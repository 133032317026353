import { View, Text, StyleSheet } from 'react-native'

export default function index(props) {
  return (
    <View style={styles.container}>
      <Text>Statistic</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    
  }
})